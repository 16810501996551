// extracted by mini-css-extract-plugin
export var dNone = "projekte-module--d-none---OZXy";
export var dBlock = "projekte-module--d-block--UHr1d";
export var dInline = "projekte-module--d-inline--EgwOt";
export var dFlex = "projekte-module--d-flex--Wf-DK";
export var dMdNone = "projekte-module--d-md-none--swLO3";
export var dMdBlock = "projekte-module--d-md-block--Sq7Kd";
export var dMdFlex = "projekte-module--d-md-flex--eFWG2";
export var dLgFlex = "projekte-module--d-lg-flex--cOF68";
export var dLgNone = "projekte-module--d-lg-none--0hse8";
export var dLgBlock = "projekte-module--d-lg-block--X07z9";
export var dLgInline = "projekte-module--d-lg-inline--q3CpO";
export var projectSection = "projekte-module--projectSection--GV75j";
export var projectList = "projekte-module--projectList--P-QIr";
export var project = "projekte-module--project--HADpe";
export var projectImg = "projekte-module--projectImg--frN+M";
export var caption = "projekte-module--caption--jcOXj";
export var date = "projekte-module--date--ihgZp";
export var title = "projekte-module--title--aplOw";
export var projectOverviewWrapper = "projekte-module--projectOverviewWrapper--AgcnU";