import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import {
  caption,
  date,
  project,
  projectImg,
  projectList,
  projectOverviewWrapper,
  title,
} from "./styles/projekte.module.scss";

import { modifyDateString } from "../util/util";

const Projekte = ({ data }) => {
  const [projects, setProjects] = useState(
    data.allStrapiProjects?.nodes.map((projectEntry) => {
      modifyDateString(projectEntry);
      return projectEntry;
    }),
  );

  useEffect(() => {
    setProjects([...projects].sort(() => 0.5 - Math.random()));
  }, []);

  return (
    <section className={`${projectOverviewWrapper} fade-in`}>
      <div className={projectList}>
        {projects.map((projectEntry, projectIndex) => (
          <Link
            to={`/projekte/${projectEntry.slug}/`}
            className={`${project} scale-up-hover`}
            key={`${projectEntry.slug}-${projectEntry.date}`}
          >
            <GatsbyImage
              loading={projectIndex > 3 ? "lazy" : "eager"}
              className={projectImg}
              image={getImage(projectEntry.images[0].localFile)}
              alt={projectEntry.Title}
            ></GatsbyImage>
            <div className={caption}>
              <p className={date}>{projectEntry.date}</p>
              <p className={title}>{projectEntry.Title}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

Projekte.seoOptions = {
  title: "Projekte",
};
export default Projekte;

export const query = graphql`
  query {
    allStrapiProjects {
      nodes {
        Title
        date(formatString: "MM/YYYY", locale: "de-DE")
        description
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: CONSTRAINED
                quality: 50
              )
            }
          }
        }
        info
        location
        slug
        year_only
      }
    }
  }
`;
