export const modifyDateString = (data) => {
  let splittedDate = data.date.split("/");
  if(data.year_only === true) {
    if(splittedDate.length)
      data.date = splittedDate[splittedDate.length - 1];
  } else {
    data.date = splittedDate[0] + '/' + splittedDate[1].slice(-2);
  }
    
  return data;
};